<template>
  <div>
    <nav-bar></nav-bar>
    <tool-box></tool-box>

    <!-- 顶部菜单 -->
    <el-affix>
      <el-menu mode="horizontal" menu-trigger="hover" collapse-transition="false">
        <div class="container">
          <el-row class="new-home-top">
            <el-col :span="4">
              <span style="font-size: 20px;cursor: pointer;" @click="this.$router.push('/news')">
                {{ currentSpecial.title }}
                <svg-icon iconClass="jiantouarrow486"></svg-icon>
              </span>
            </el-col>
            <el-col :span="4" v-for="(type,index) in typeList" :key="type.id">
              <span class="el-dropdown-link" @click="goNewsList(type)">
                {{ type.title }}
                <span style="float: right;" v-if="index < typeList.length - 1">|</span>
              </span>
            </el-col>
          </el-row>
        </div>
      </el-menu>
    </el-affix>
    
    <div class="container center">
      <el-row :gutter="10">
        <el-col :span="14">
          <!-- 资讯列表 -->
          <div class="news-content">
            <div class="news-content-title">{{ currentSpecial.title + currentType.title }}</div>
            <div class="news-content-list">
              <el-empty v-if="newsList.length == 0" description="暂无"></el-empty>
              <div v-else class="news-content-list-item" v-for="item in newsList" :key="item.id" @click="goNewsDetail(item)">
                <span class="item-title"><span>{{ item.article_title }}</span></span>
                <span class="item-time">{{ item.create_time.substring(0, 10) }}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="relevant-content">
            <div class="relevant-content-title">相关题库</div>
            <div class="relevant-content-exam">
              <el-empty v-if="examList.length == 0" description="暂无"></el-empty>
              <div v-else class="exam-item" v-for="item in examList" :key="item.id"
                :title="item.title" @click="goQuestion(item)">
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div class="relevant-content-title">相关课程</div>
            <div class="relevant-content-class">
              <el-empty v-if="classList.length == 0" description="暂无"></el-empty>
              <div v-else class="class-item" v-for="item in classList" :key="item.id">
                <combo-class-item :item='item'></combo-class-item>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script>
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import SvgIcon from '@/components/svgicon'
import ComboClassItem from './components/ComboClassItem'
import { getArtClaList, getSpecClaList, getComboBySpec } from "@/api/news"
import { setSessionStorage } from '@/utils/auth'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
    SvgIcon,
    ComboClassItem
  },
  data() {
    return {
      // 类型列表
      typeList: [],
      // 当前类型
      currentType: {},
      // 当前专题
      currentSpecial: {},
      // 相关题库
      examList: [],
      // 相关班级
      classList: [],
      // 资讯文章列表
      newsList: [],
      // 查询列表参数
      queryParams: {
        spec_id: null,
        pid: null,
        page: 1,
        limit: 1000
      },

    }
  },
  created() {
    // 获取到路由中参数
    this.queryParams.spec_id = this.$route.params.specId
    this.queryParams.pid = this.$route.params.typeId
    // 获取分类
    getArtClaList({pid: 5}).then(res => {
      if (res.code == 1) {
        this.typeList = res.data
        res.data.forEach(item => {
          if (item.id == this.queryParams.pid) {
            this.currentType = item
          }
        })
      }
    })
    // 获取相关列表
    getComboBySpec({spec_id: this.queryParams.spec_id}).then(res => {
      if (res.code == 1) {
        this.currentSpecial = res.data.info
        this.examList = res.data.exam.slice(-5)
        this.classList = res.data.comboclass
      }
    })
    this.getNewsList()
  },
  methods: {
    // 获取资讯列表
    getNewsList() {
      getSpecClaList(this.queryParams).then(res => {
        if (res.code == 1) {
          this.newsList = res.data
        }
      })
    },
    // 跳转到详情
    goNewsDetail(news) {
      this.$router.push('/newsdetail/' + news.article_id)
    },
    // 跳转到列表页
    goNewsList(type) {
      this.$router.push('/newslist/' + this.currentSpecial.id + '/' + type.id)
    },
    // 做题
    goQuestion(paper) {
      setSessionStorage('currentPaper', paper)
      this.$router.push('/question')
    },

  },
  watch: {
    $route() {
      // 获取到路由中参数
      this.queryParams.spec_id = this.$route.params.specId
      this.queryParams.pid = this.$route.params.typeId
      // 更新类型
      this.typeList.forEach(item => {
        if (item.id == this.queryParams.pid) {
          this.currentType = item
        }
      })
      // 更新列表
      this.getNewsList()
    }
  },
}
</script>

<style scoped lang="scss">
.center {
  margin: 30px auto;
}
// 顶部菜单 begin
.el-menu {
  background-color: $theme-color;
}
.new-home-top {
  color: #fff;
  width: 100%;
  height: 73px;
  display: flex;
  align-items: center;
  text-align: center;
}
.new-home-top-title {
  font-size: 22px;
}
.new-home-top:not(.no-border) {
  border-right: 2px solid #fff;
}
.new-home-top:not(.no-border) {
  border-right: 0;
}
.el-dropdown-link {
  color: #fff;
  width: 200px;
  line-height: 50px;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
}
// 顶部菜单 end

// 资讯列表 begin
.news-content {
  background-color: $background-color;
  padding: 20px 25px;
  .news-content-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .news-content-list {
    margin: 20px 0;
    .news-content-list-item {
      height: 66px;
      background-color: #f6f9ff;
      border-radius: 4px;
      padding: 0 10px;
      margin: 35px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .item-title {
        width: 545px;
        font-size: 18px;
        letter-spacing: 1px;
        display: inline-flex;
        align-items: center;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .item-title::before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: $primary-color;
        border-radius: 50%;
        margin-right: 10px;
      }
      .item-time {
        color: #686b76;
      }
    }
    .news-content-list-item:hover {
      background-color: $theme-color;
    }
    .news-content-list-item:hover .item-title,
    .news-content-list-item:hover .item-time {
      color: #fff;
    }
  }
}
// 资讯列表 end

// 相关列表 begin
.relevant-content {
  background-color: $background-color;
  padding: 20px;
  .relevant-content-title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px
  }
  // 相关题库
  .relevant-content-exam {
    margin: 20px 0 50px 0;
    .exam-item {
      height: 66px;
      font-size: 16px;
      background-color: #f6f9ff;
      border-radius: 4px;
      padding: 0 20px;
      margin: 10px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        width: 450px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .exam-item::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: $danger-color;
      border-radius: 50%;
      margin-right: 10px;
    }
    .exam-item:hover {
      color: #fff;
      background-color: $theme-color;
    }
  }
  // 相关课程
  .relevant-content-class {
    .class-item {
      width: 320px;
      margin: 20px;
      .class-item-title {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .class-item:hover {
      color: $theme-color;
    }
  }
}
// 相关列表 end
</style>
